export const menuConfig = [
  {
    title: "Network",
    items: [
      // { title: "XRAY/Network", link: "/wiki/xray-network" },
      // { title: "XRAY/Foundation", link: "/wiki/xray-foundation" },
      { title: "XRAY/App", link: "/wiki/xray-app" },
      // { title: "XRAY/Vault", link: "/wiki/xray-vault" },
      // { title: "XRAY & Tokenomics", link: "/wiki/xray-tokenomics" },
    ],
  },
  {
    title: "General",
    items: [
      { title: "Mini Apps", link: "/wiki/mini-apps" },
      { title: "Roadmap", link: "/wiki/roadmap" },
      // { title: "Changelog", link: "/wiki/changelog" },
      // { title: "GitHub Updates", link: "/wiki/github-updates" },
      // { title: "Social Media", link: "/wiki/social-media" },
      { title: "Install PWA", link: "/wiki/install-pwa" },
    ],
  },
  // {
  //   title: "Cardano",
  //   items: [
  //     { title: "Wallets & Accounts", link: "/wiki/cardano/wallets-and-accounts" },
  //     { title: "ADA Staking", link: "/wiki/cardano/ada-staking" },
  //     { title: "DRep", link: "/wiki/cardano/drep" },
  //     { title: "Smart Contracts", link: "/wiki/cardano/smart-contracts" },
  //   ],
  // },
  // {
  //   title: "Developers",
  //   items: [
  //     { title: "Mini Apps", link: "/wiki/dev/mini-apps" },
  //     { title: "CardanoWeb3.js", link: "/wiki/dev/cardano-web3-js" },
  //     { title: "XRAY/Graph", link: "/wiki/dev/xray-graph" },
  //   ],
  // },
  {
    title: "Legal",
    items: [
      { title: "Terms of Service", link: "/wiki/terms-of-service" },
      { title: "Privacy Policy", link: "/wiki/privacy-policy" },
    ],
  },
]
