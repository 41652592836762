/**
 * Transaction Types
 */

import { ConfigTypes } from "@/config"

export interface PendingTxs {
  [key: string]: {
    hash: string
    seenOnBlockchain: boolean
    timestamp: number
  }
}

export interface ISettings {
  pendingTxs: PendingTxs
  transaction:
    | {
        tx: string
        kind: string
        outputs?: ConfigTypes.T.Output[]
      }
    | undefined
}

/**
 * Transaction Actions
 */

export enum Enum {
  CLEAR = "metadata/CLEAR",

  TRANSACTION_SET = "app/TRANSACTION_SET",
  PENDING_TXS_SET = "app/PENDING_TXS_SET",
  PENDING_TX_UPDATE_SAGA = "transaction/PENDING_TX_UPDATE_SAGA",
  PENDING_TX_REMOVE_SAGA = "transaction/PENDING_TX_REMOVE_SAGA",
  PENDING_TXS_CHECK_SAGA = "transaction/PENDING_TXS_CHECK_SAGA",
}

export type AClear = {
  type: typeof Enum.CLEAR
}

export type ATransactionSet = {
  type: typeof Enum.TRANSACTION_SET
  tx: string
  kind: string
  outputs?: ConfigTypes.T.Output[]
}

export type APendingTxsSet = {
  type: typeof Enum.PENDING_TXS_SET
  pendingTxs: PendingTxs
}

export type APendingTxsUpdateSaga = {
  type: typeof Enum.PENDING_TX_UPDATE_SAGA
  hash: string
}

export type APendingTxsRemoveSaga = {
  type: typeof Enum.PENDING_TX_REMOVE_SAGA
}

export type APendingTxsCheckSaga = {
  type: typeof Enum.PENDING_TXS_CHECK_SAGA
}

export type Actions =
  | AClear
  | ATransactionSet
  | APendingTxsSet
  | APendingTxsUpdateSaga
  | APendingTxsRemoveSaga
  | APendingTxsCheckSaga
