import React, { useEffect, useState } from "react"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import LinkExplorer from "@/components/common/LinkExplorer"

const Readonly = () => {
  return (
    <div className="mt-4 mb-4 pb-2">
      <div className="text-center text-blue mb-3">
        <ExclamationCircleOutlined style={{ fontSize: 72 }} />
      </div>
      <div className="text-center mt-2 font-size-24 mb-4">
        <strong>Readonly Account</strong>
      </div>
      {/* <div className="text-center text-muted mt-2 mb-4">
        <LinkExplorer title={tx} link={tx} type="transaction" />
      </div> */}
      <div className="text-center w-max-400 text-muted font-size-14 mx-auto">
        You are viewing a readonly account. You can't send transactions from this account. Please switch to a different
        account to send transactions.
      </div>
    </div>
  )
}

export default Readonly
