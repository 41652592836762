import React, { useEffect, useState } from "react"
import { Modal, Button, Switch, Radio, Select, Input, Popconfirm, Statistic } from "antd"
import { navigate } from "gatsby"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import * as style from "./style.module.scss"
import { AppActions, AppTypes } from "@/redux/app"
import { AccountActions, AccountSelectors, AccountTypes } from "@/redux/account"
import Informers from "@/components/informers"
import * as utils from "@/utils"
import { ConfigTypes } from "@/config"

const GeneralSettings = () => {
  const dispatch = useAppDispatch()
  const theme = useAppSelector((state) => state.app.theme)
  const scale = useAppSelector((state) => state.app.scale)
  const network = useAppSelector((state) => state.app.network)
  const explorer = useAppSelector((state) => state.app.explorer)
  const currency = useAppSelector((state) => state.app.currency)
  const hideBalances = useAppSelector((state) => state.app.hideBalances)
  const hideWelcome = useAppSelector((state) => state.app.hideWelcome)

  return (
    <div>
      {/* <div className="mb-4">
        <strong>General Settings</strong>
      </div> */}
      <div className="mb-4">
        <span className="d-flex align-items-center">
          <span>Color Theme</span>
          <span className="ms-auto text-nowrap">
            <Radio.Group
              value={theme}
              optionType="button"
              buttonStyle="solid"
              options={[
                {
                  label: (
                    <span>
                      <i className="xi xi-sun font-size-14 me-2" />
                      Light
                    </span>
                  ),
                  value: "default",
                },
                {
                  label: (
                    <span>
                      <i className="xi xi-moon font-size-14 me-2" />
                      Dark
                    </span>
                  ),
                  value: "dark",
                },
                {
                  label: (
                    <span>
                      <i className="xi xi-settings font-size-14 me-2" />
                      System
                    </span>
                  ),
                  value: "system",
                },
              ]}
              onChange={({ target: { value } }) => {
                dispatch(AppActions.THEME_SET(value))
              }}
            />
          </span>
        </span>
      </div>
      <div className="mb-4">
        <span className="d-flex align-items-center">
          <span>UI Scale</span>
          <span className="ms-auto text-nowrap">
            <Radio.Group
              value={scale}
              optionType="button"
              buttonStyle="solid"
              options={[
                {
                  label: "S",
                  value: "small",
                },
                {
                  label: "M",
                  value: "default",
                },
                {
                  label: "L",
                  value: "large",
                },
                {
                  label: "XL",
                  value: "xlarge",
                },
              ]}
              onChange={({ target: { value } }) => {
                dispatch(AppActions.SCALE_SET(value))
              }}
            />
          </span>
        </span>
      </div>
      <div className="mb-4">
        <span className="d-flex align-items-center">
          <span>Default Currency</span>
          <span className="ms-auto">
            <Select<AppTypes.Currency> value={currency} onChange={(value) => dispatch(AppActions.CURRENCY_SET(value))}>
              <Select.Option value="usd">$ USD</Select.Option>
              <Select.Option value="eur">€ EUR</Select.Option>
              <Select.Option value="gbp">£ GBP</Select.Option>
              <Select.Option value="jpy">¥ JPY</Select.Option>
              <Select.Option value="cny">¥ CNY</Select.Option>
            </Select>
          </span>
        </span>
      </div>
      <div className="mb-4">
        <span className="d-flex align-items-center">
          <span>Hide Balances</span>
          <span className="ms-auto">
            <Switch checked={hideBalances} onChange={() => dispatch(AppActions.HIDE_BALANCES_SET(!hideBalances))} />
          </span>
        </span>
      </div>
      <div className="mb-4">
        <span className="d-flex align-items-center">
          <span>Hide Welcome Widget</span>
          <span className="ms-auto">
            <Switch checked={hideWelcome} onChange={() => dispatch(AppActions.HIDE_WELCOME_SET(!hideWelcome))} />
          </span>
        </span>
      </div>
      <div className="mb-4">
        <span className="d-flex align-items-center">
          <span>Explorer</span>
          <span className="ms-auto">
            <Select<AppTypes.Explorer>
              value={explorer}
              onChange={(value) => {
                dispatch(AppActions.EXPLORER_SET(value))
              }}
            >
              <Select.Option value="cardanoscan">Cardanoscan</Select.Option>
              <Select.Option value="cexplorer">Cexplorer</Select.Option>
              <Select.Option value="adastat">Adastat</Select.Option>
            </Select>
          </span>
        </span>
      </div>
      <div className="xray-line xray-line-dashed mt-2 mb-4" />
      <div className="mb-4">
        <span className="d-flex align-items-center">
          <span>Network Type</span>
          <span className="ms-auto">
            <Select<ConfigTypes.NetworkName>
              value={network.name}
              onChange={(value) => {
                navigate("/" as any)
                dispatch(AppActions.NETWORK_UPDATE_SAGA(value))
              }}
            >
              <Select.Option value="mainnet">Mainnet</Select.Option>
              <Select.Option value="preprod">Preprod</Select.Option>
              <Select.Option value="preview">Preview</Select.Option>
            </Select>
          </span>
        </span>
      </div>
    </div>
  )
}

export default GeneralSettings
