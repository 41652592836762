import React, { useEffect, useState } from "react"
import { Modal, Button, Collapse, Form, Input, Tabs, Alert } from "antd"
import classnames from "classnames"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import { SVGCardano } from "@/svg"
import * as style from "./style.module.scss"
import { AppActions } from "@/redux/app"
import { browserConnectors, browserConnectorData, ConfigTypes } from "@/config"
import { AccountActions, AccountSelectors } from "@/redux/account"
import * as utils from "@/utils"

const Installed = () => {
  const dispatch = useAppDispatch()
  const web3 = useAppSelector((state) => state.app.web3.instance)

  const [browserConnectorsAvailable, setBrowserConnectorsAvailable] = useState<ConfigTypes.BrowserConnectorData[]>([])

  useEffect(() => {
    if (web3) {
      ;(async () => {
        const available = (await web3.connector.list()).map((connector) => browserConnectorData(connector))
        setBrowserConnectorsAvailable(available)
      })()
    }
  }, [web3])

  const connectBrowserConnector = async (connectorId: string) => {
    dispatch(AccountActions.ADD_BROWSER_CONNECTOR_SAGA(connectorId))
  }

  return (
    <div>
      {browserConnectorsAvailable.length <= 0 && (
        <div className="xray-empty mt--2 mb--2 py-4">
          <div className="text-center">
            <strong>No wallet extensions are installed in your browser</strong>
            <br />
            <span className="text-muted">Check the "Popular" tab to install one</span>
          </div>
        </div>
      )}
      {browserConnectorsAvailable.length > 0 &&
        browserConnectorsAvailable.map((connector, index) => {
          return (
            <div className="xray-connect" key={index}>
              <div className="xray-connect-item" onClick={() => connectBrowserConnector(connector.id)}>
                <span className="xray-connect-item-img">
                  <img
                    src={connector.imgUrl}
                    alt={connector.name}
                    className={classnames("xray-connect-item-icon", {
                      "xray-inverse-on-dark": connector.imgInversible,
                    })}
                  />
                </span>
                <span className="xray-connect-item-title">
                  <strong className="d-flex align-items-center justify-items-center">{connector.name}</strong>
                </span>
                <span className="xray-connect-item-status">
                  <i className="xi xi-arrow_forward me-1" />
                </span>
              </div>
            </div>
          )
        })}
    </div>
  )
}

export default Installed
