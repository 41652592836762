export const miniAppsConfig: {
  [key: string]: {
    id: string
    name: string
    network: string[]
    icon: string
    description: string
    link?: string
  }[]
} = {
  official: [
    {
      id: "xray-wallet",
      name: "Wallet",
      network: ["mainnet", "preprod", "preview"],
      icon: "xray-wallet.png",
      description: "Secure and user-friendly Cardano wallet",
      link: "/wallet",
    },
    {
      id: "xray-assets",
      name: "Assets",
      network: ["mainnet", "preprod", "preview"],
      icon: "xray-assets.png",
      description: "Explore your account's assets & NFTs",
      link: "/assets",
    },
    {
      id: "xray-transactions",
      name: "Transactions",
      network: ["mainnet", "preprod", "preview"],
      icon: "xray-transactions.png",
      description: "Your account's transaction history",
      link: "/transactions",
    },
    {
      id: "xray-stake",
      name: "Stake",
      network: ["mainnet", "preprod", "preview"],
      icon: "xray-stake.png",
      description: "Stake Cardano assets for passive rewards",
      // link: "/stake",
    },
    {
      id: "xray-ai",
      name: "AI",
      network: ["mainnet", "preprod", "preview"],
      icon: "xray-ai.png",
      description: "Advanced blockchain-driven AI tools and insights",
      // link: "/ai",
    },
    {
      id: "xray-premium",
      name: "Premium",
      network: ["mainnet"],
      icon: "xray-premium.png",
      description: "Exclusive features and benefits for XRAY Premium users",
    },
    {
      id: "xray-explorer",
      name: "Explorer",
      network: ["mainnet", "preprod", "preview"],
      icon: "xray-explorer.png",
      description: "Track Cardano transactions and blockchain stats",
      // link: "/explorer",
    },
    {
      id: "xray-governance",
      name: "Governance",
      network: ["mainnet"],
      icon: "xray-governance.png",
      description: "Participate in XRAY/Network governance",
    },
    {
      id: "xray-wiki",
      name: "Wiki",
      network: ["mainnet", "preprod", "preview"],
      icon: "xray-wiki.png",
      description: "Comprehensive knowledge base for Cardano",
      link: "/wiki",
    },
    {
      id: "xray-swap",
      name: "Swap",
      network: ["mainnet"],
      icon: "xray-swap.png",
      description: "DEX aggregator & token trading platform",
    },
    {
      id: "xray-pad",
      name: "Pad",
      network: ["mainnet"],
      icon: "xray-pad.png",
      description: "Launchpad for Cardano-based blockchain projects",
    },
    {
      id: "xray-list",
      name: "List",
      network: ["mainnet"],
      icon: "xray-list.png",
      description: "Token data and real-time pricing platform",
    },
    {
      id: "minterr-io",
      name: "Minterr",
      network: ["mainnet", "preprod", "preview"],
      icon: "minterr.png",
      description: "Effortless NFT creation on Cardano",
    },
    {
      id: "pigz-io",
      name: "Pigz.io",
      network: ["mainnet"],
      icon: "pigz-io.png",
      description: "Fun Telegram-based Cardano gaming app",
    },
  ],
  external: [],
  widgets: [],
  dev: [
    {
      id: "xray-graph",
      name: "Graph",
      network: ["mainnet", "preprod", "preview"],
      icon: "xray-graph.png",
      description: "Robust APIs for Cardano blockchain developers",
      // link: "/graph",
    },
    {
      id: "cardano-web3-js",
      name: "CardanoWeb3Js",
      network: ["mainnet", "preprod", "preview"],
      icon: "cardano-web3-js.png",
      description: "TypeScript library for seamless Cardano blockchain integration",
      // link: "/cardano-web3-js",
    },
    {
      id: "xray-ma-loader",
      name: "Mini App Loader",
      network: ["mainnet", "preprod", "preview"],
      icon: "mini-app-loader-advanced.png",
      description: "Mini App preview tool with advanced features",
    },
    {
      id: "xray-ma-registrar",
      name: "Mini App Registrar",
      network: ["mainnet", "preprod", "preview"],
      icon: "mini-app-registrar.png",
      description: "Mini App registration tool",
    },
    // {
    //   id: "faucet",
    //   name: "Faucet",
    //   active: false,
    //   network: ["preprod"],
    //   icon: "xray-faucet.png",
    //   description: "Testnet tokens faucet for Cardano developers",
    // },
  ],
}
