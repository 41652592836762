/**
 * Metadata Types
 */

export interface ISettings {
  // decimalsStore: { [key: string]: number }
}

/**
 * Metadata Actions
 */

export enum Enum {
  CLEAR = "metadata/CLEAR",
}

export type AClear = {
  type: typeof Enum.CLEAR
}

export type Actions = AClear
