import React, { useEffect, useState } from "react"
import { message, notification } from "antd"
import { useAppSelector, useAppDispatch } from "./provider"
import { AccountActions, AccountTypes, AccountSelectors } from "./account"
import { TransactionActions, TransactionTypes, TransactionSelectors } from "./transaction"
import { AppActions, AppTypes } from "@/redux/app"

let interval10: ReturnType<typeof setInterval>
let interval30: ReturnType<typeof setInterval>
let interval600: ReturnType<typeof setInterval>

const Runner = ({ children }: { children: any }) => {
  // useEffect(() => {
  //   message.success("Success!", 100000)
  //   message.info("Welcome to the new version of the app!", 100000)
  //   message.error("Welcome to the new version of the app!", 100000)
  //   notification.success({
  //     message: "Success",
  //     description: "You have successfully subscribed to our newsletter",
  //     duration: 100000,
  //   })
  //   notification.error({
  //     message: "Wrong Email",
  //     description: "Please enter a valid email address",
  //     duration: 100000,
  //   })
  // }, [])

  const dispatch = useAppDispatch()
  const network = useAppSelector((state) => state.app.network)
  const web3 = useAppSelector((state) => state.app.web3.instance)
  const connectorsOnline = useAppSelector((state) => state.app.connectorsOnline)
  const accounts = useAppSelector(AccountSelectors.accounts)
  const currentAccountId = useAppSelector(AccountSelectors.currentAccountId)
  const tip = useAppSelector((state) => state.app.tip)
  const transaction = useAppSelector((state) => state.transaction.transaction)
  const currentAccountPendingTx = useAppSelector(TransactionSelectors.currentAccountPendingTx)

  // Init web3 on network change
  useEffect(() => {
    dispatch(AppActions.WEB3_INIT_SAGA(network.name))
  }, [network])

  // Handle interval actions change
  useEffect(() => {
    if (web3) {
      dispatch(AppActions.CONNECTORS_ONLINE_UPDATE_SAGA())
      dispatch(AppActions.TIP_UPDATE_SAGA())
      dispatch(AppActions.FIAT_PRICE_UPDATE_SAGA())
      dispatch(TransactionActions.PENDING_TXS_CHECK_SAGA())

      interval10 = setInterval(() => {
        dispatch(AppActions.CONNECTORS_ONLINE_UPDATE_SAGA()) // check connectors online
        dispatch(TransactionActions.PENDING_TXS_CHECK_SAGA()) // check pending txs if any
      }, 10_000)

      interval30 = setInterval(() => {
        dispatch(AppActions.TIP_UPDATE_SAGA()) // update tip
      }, 30_000)

      interval600 = setInterval(() => {
        dispatch(AppActions.FIAT_PRICE_UPDATE_SAGA()) // update fiat price
      }, 600_000)
    }
    return () => {
      clearInterval(interval10)
      clearInterval(interval30)
      clearInterval(interval600)
    }
  }, [web3])

  // Init instances on accounts list, accounts change, connectors online change
  useEffect(() => {
    if (web3) {
      dispatch(AccountActions.INIT_INSTANCES_SAGA())
    }
  }, [connectorsOnline])

  // Update balance on accounts list change
  useEffect(() => {
    if (web3 && tip && accounts.length > 0) {
      dispatch(AccountActions.INIT_INSTANCES_SAGA())
      accounts.forEach((account) => {
        dispatch(AccountActions.UPDATE_BALANCE_SAGA(account.id, true))
      })
    }
  }, [tip, accounts])

  // Update balance on account change
  useEffect(() => {
    if (web3 && currentAccountId) {
      dispatch(AccountActions.INIT_INSTANCES_SAGA())
      dispatch(AccountActions.UPDATE_BALANCE_SAGA(currentAccountId, false))
    }
  }, [currentAccountId])

  // Remove seen on blockchain pending transaction
  useEffect(() => {
    if (currentAccountPendingTx?.seenOnBlockchain) {
      dispatch(TransactionActions.PENDING_TX_REMOVE_SAGA())
    }
  }, [transaction])

  return children
}

export default Runner
