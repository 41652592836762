import React from "react"
import { truncate } from "@/utils"
import { Tooltip, message } from "antd"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { useAppSelector, useAppDispatch } from "@/redux/provider"

const LinkExplorer = ({
  title,
  link,
  truncate: __truncate,
  type,
}: {
  title: string
  link: string
  truncate?: number
  type: "transaction" | "token" | "block" | "stakeKey" | "pool"
}) => {
  // const appNetwork = useAppSelector((state) => state.app.network)
  // const mapLinks = appNetwork?.explorer as any

  return (
    <span className="ray-link" onClick={(e) => e.stopPropagation()}>
      <CopyToClipboard text={title} onCopy={() => message.success("Copied")}>
        <Tooltip title="Copy">
          <span className="cursor-pointer me-2">
            Tx: {truncate(title, __truncate, 4)} <i className="xi xi-copy font-size-14" />
          </span>
        </Tooltip>
      </CopyToClipboard>
      {/* <Tooltip title="Open in Explorer">
        <a href={`${mapLinks[type]}${link}`} target="_blank" rel="noopener noreferrer">
          <i className="xi xi-compass font-size-14" />
        </a>
      </Tooltip> */}
    </span>
  )
}

export default LinkExplorer
