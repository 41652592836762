import React, { useEffect, useState } from "react"
import { Modal, Button, Collapse, Form, Input, Tabs, Checkbox, FormProps, Alert } from "antd"
import classnames from "classnames"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import { SVGCardano } from "@/svg"
import { Link } from "gatsby"
import * as style from "./style.module.scss"
import { AppActions } from "@/redux/app"
import { browserConnectors, browserConnectorData, ConfigTypes } from "@/config"
import { AccountActions, AccountSelectors } from "@/redux/account"
import * as utils from "@/utils"
import { set } from "store/dist/store.legacy.min"

type FormFieldsType = {
  key: string
  password: string
  confirm: string
}

const Unlock = () => {
  const dispatch = useAppDispatch()
  const [form] = Form.useForm()
  const web3 = useAppSelector((state) => state.app.web3.instance)
  const [mnemonicApproved, setMnemonicApproved] = useState(false)
  const [passwordApproved, setPasswordApproved] = useState(false)

  const isXprvKey = (value: string) => {
    return value.length === 165 && !value.includes(" ") && web3?.utils.keys.xprvKeyValidate(value)
  }

  const unlockWallet: FormProps<FormFieldsType>["onFinish"] = (values) => {
    const { key, password } = values
    const xprvKey = isXprvKey(key) ? key : web3?.utils.keys.mnemonicToXprvKey(key)
    dispatch(AccountActions.ADD_XPRV_KEY_SAGA(xprvKey!, password))
    clearForm()
  }

  const clearForm = () => {
    form.resetFields()
    setMnemonicApproved(false)
    setPasswordApproved(false)
  }

  return (
    <div>
      <p className="text-muted">Enter the 12, 15 or 24-word mnemonic seed phrase or the xprv key</p>
      <Form form={form} layout="vertical" requiredMark={false} onFinish={unlockWallet}>
        <Form.Item<FormFieldsType>
          label="Mnemonic Phrase or Xprv Key"
          name="key"
          rules={[
            {
              required: true,
              message: "Mnemonic Phrase or Xprv Key is required",
            },
            () => ({
              validator(_, value) {
                if (!value) {
                  setMnemonicApproved(false)
                  return Promise.resolve()
                }
                if (isXprvKey(value)) {
                  setMnemonicApproved(true)
                  return Promise.resolve()
                }
                if (web3?.utils.keys.mnemonicValidate(value)) {
                  setMnemonicApproved(true)
                  return Promise.resolve()
                }
                setMnemonicApproved(false)
                return Promise.reject(new Error("Mnemonic Phrase or Xprv Key is wrong"))
              },
            }),
          ]}
          hasFeedback
        >
          <Input.TextArea size="large" autoComplete="off" autoSize placeholder="Enter Mnemonic or Xprv Key..." />
        </Form.Item>

        <Form.Item<FormFieldsType>
          hidden={!mnemonicApproved}
          name="password"
          label="Set Password"
          rules={[
            {
              required: true,
              message: "Please input your password",
            },
            {
              min: 8,
              message: "Minimum 8 symbols",
            },
          ]}
          hasFeedback
        >
          <Input.Password size="large" />
        </Form.Item>

        <Form.Item<FormFieldsType>
          hidden={!mnemonicApproved}
          name="confirm"
          label="Confirm Password"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your password",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  setPasswordApproved(true)
                  return Promise.resolve()
                }
                setPasswordApproved(false)
                return Promise.reject(new Error("The passwords do not match"))
              },
            }),
          ]}
        >
          <Input.Password size="large" />
        </Form.Item>

        <Form.Item className="mb-0">
          <Button
            shape="round"
            // disabled={!(passwordApproved && mnemonicApproved)}
            htmlType="submit"
            size="large"
            type="primary"
          >
            <i className="xi xi-unlock" />
            <strong>Unlock Account</strong>
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default Unlock
