import React, { useEffect, useState } from "react"
import { Modal, Button, Collapse, Form, Input, Tabs, Alert } from "antd"
import classnames from "classnames"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import { SVGCardano } from "@/svg"
import * as style from "./style.module.scss"
import { AppActions } from "@/redux/app"
import { browserConnectors, browserConnectorData, ConfigTypes } from "@/config"
import { AccountActions, AccountSelectors } from "@/redux/account"
import * as utils from "@/utils"
import Installed from "./Installed"
import Popular from "./Popular"

const BrowserConnectors = () => {
  const dispatch = useAppDispatch()
  const [activeTab, setActiveTab] = useState("installed")

  return (
    <div>
      <Tabs
        className="mt--3"
        activeKey={activeTab}
        onChange={(key) => setActiveTab(key)}
        items={[
          {
            key: "installed",
            label: (
              <strong>
                <i className="xi xi-category me-1"></i> Installed
              </strong>
            ),
          },
          {
            key: "popular",
            label: (
              <strong>
                <i className="xi xi-star me-1"></i> Popular
              </strong>
            ),
          },
        ]}
      />
      {activeTab === "installed" && <Installed />}
      {activeTab === "popular" && <Popular />}
    </div>
  )
}

export default BrowserConnectors
