import React, { useEffect, useState } from "react"
import { Modal, Button, Collapse, Form, Input, Tabs, FormProps } from "antd"
import classnames from "classnames"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import { SVGCardano } from "@/svg"
import * as style from "./style.module.scss"
import { AppActions } from "@/redux/app"
import { browserConnectors, browserConnectorData, ConfigTypes } from "@/config"
import { AccountActions, AccountSelectors } from "@/redux/account"
import * as utils from "@/utils"

type FormFieldsType = {
  address: string
}

const Readonly = () => {
  const dispatch = useAppDispatch()
  const web3 = useAppSelector((state) => state.app.web3.instance)
  const network = useAppSelector((state) => state.app.network)
  const [readonlyForm] = Form.useForm()

  const unlockReadonly: FormProps<FormFieldsType>["onFinish"] = (values) => {
    dispatch(AccountActions.ADD_READ_ONLY_SAGA(values.address))
    readonlyForm.resetFields()
  }

  return (
    <div>
      <p className="text-muted">Connect read-only account by spending address</p>
      <Form form={readonlyForm} layout="vertical" requiredMark={false} onFinish={unlockReadonly}>
        <Form.Item<FormFieldsType>
          name="address"
          // className="flex-grow-1 mb-2"
          rules={[
            {
              required: true,
              message: "Address is required",
            },
            () => ({
              validator(_, value) {
                if (!value) {
                  return Promise.resolve()
                }
                if (network.id === web3?.utils.address.getNetwork(value)) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error("Address is wrong or network is not matched"))
              },
            }),
          ]}
          hasFeedback
        >
          <Input
            size="large"
            autoComplete="off"
            placeholder={`${network.id === 1 ? "Enter addr1..." : "Enter addr_test1..."}`}
          />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" size="large" type="primary" shape="round">
            <i className="xi xi-add me-1" /> Connect
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default Readonly
