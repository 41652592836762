import { all, takeEvery, put, call, select, fork, takeLatest, delay, cancelled } from "redux-saga/effects"
import { message, notification } from "antd"
import { TransactionActions, TransactionSelectors, TransactionTypes } from "."
import { AccountActions, AccountTypes, AccountSelectors } from "@/redux/account"
import { typedSelect, typedCall } from "@/redux/provider"
import { getFiatPrice } from "@/services/fiatPrice"
import * as Utils from "@/utils"
import * as Config from "@/config"
import { CardanoWeb3 } from "cardano-web3-js"

export function* PENDING_TX_UPDATE_SAGA({ hash }: TransactionTypes.APendingTxsUpdateSaga) {
  const pendingTxs = yield* typedSelect((state) => state.transaction.pendingTxs)
  const currentAccountId = yield* typedSelect(AccountSelectors.currentAccountId)
  if (currentAccountId) {
    yield put(
      TransactionActions.PENDING_TXS_SET({
        ...pendingTxs,
        [currentAccountId]: {
          hash,
          seenOnBlockchain: false,
          timestamp: Date.now(),
        },
      })
    )
  }
}

export function* PENDING_TX_REMOVE_SAGA() {
  const pendingTxs = yield* typedSelect((state) => state.transaction.pendingTxs)
  const currentAccountPendingTx = yield* typedSelect(TransactionSelectors.currentAccountPendingTx)
  const currentAccountId = yield* typedSelect(AccountSelectors.currentAccountId)
  if (currentAccountPendingTx) {
    const __pendingTxs = { ...pendingTxs }
    delete __pendingTxs[currentAccountId || ""]
    yield put(TransactionActions.PENDING_TXS_SET(__pendingTxs))
  }
}

export function* PENDING_TXS_CHECK_SAGA() {
  const abortController = new AbortController()
  const web3 = yield* typedSelect((state) => state.app.web3.instance)
  if (!web3) return

  try {
    const pendingTxs = yield* typedSelect((state) => state.transaction.pendingTxs)
    const currentAccountId = yield* typedSelect(AccountSelectors.currentAccountId)
    if (Object.keys(pendingTxs || {}).length === 0 || !currentAccountId) return

    const __pendingTxs = { ...pendingTxs }
    Object.entries(__pendingTxs).forEach(([key, value]) => {
      if (Date.now() - value?.timestamp > Config.TTL * 1000) {
        delete __pendingTxs[key]
        if (key === currentAccountId) {
          notification.error({
            message: "Transaction Expired",
            description: `TX ID: ${Utils.truncate(value.hash)}`,
          })
        }
      }
    })

    const txsFound: any = yield* typedCall(web3.explorer.koios.POST, "/tx_info", {
      signal: abortController.signal,
      // @ts-ignore
      body: {
        _tx_hashes: Object.entries(__pendingTxs).map(([key, value]) => {
          return value.hash
        }),
      },
    })

    if (txsFound?.data) {
      const __txsFound =
        txsFound?.data.reduce((acc: any, tx: any) => {
          if (tx.tx_hash) {
            return [...acc, tx.tx_hash]
          } else {
            return acc
          }
        }, [] as string[]) || []

      if (__txsFound.length > 0) {
        const txsNotApplied = Object.entries(__pendingTxs).reduce((acc, [key, value]) => {
          if (__txsFound.includes(value.hash) && !value.seenOnBlockchain) {
            if (key === currentAccountId) {
              notification.success({
                message: "Tx Succesfully Sent",
                description: `TX ID: ${Utils.truncate(value.hash)}`,
              })
            }
            return {
              ...acc,
              [currentAccountId]: {
                ...value,
                seenOnBlockchain: true,
              },
            }
          }
          return { ...acc, [currentAccountId]: value }
        }, {})
        yield put(TransactionActions.PENDING_TXS_SET(txsNotApplied))
        // yield put(AccountActions.ACCOUNT_INFO_UPDATE_SAGA("silent"))
      }
    }
  } finally {
    const isCancelled: boolean = yield cancelled()
    if (isCancelled) {
      abortController.abort()
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(TransactionTypes.Enum.PENDING_TX_UPDATE_SAGA, PENDING_TX_UPDATE_SAGA),
    takeLatest(TransactionTypes.Enum.PENDING_TX_REMOVE_SAGA, PENDING_TX_REMOVE_SAGA),
    takeLatest(TransactionTypes.Enum.PENDING_TXS_CHECK_SAGA, PENDING_TXS_CHECK_SAGA),
  ])
}
