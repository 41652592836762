import React, { useEffect, useState } from "react"
import { Modal, Tabs } from "antd"
import { navigate } from "gatsby"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import { AppActions, AppTypes } from "@/redux/app"
import { AccountActions, AccountSelectors, AccountTypes } from "@/redux/account"
import NetworkStats from "@/components/common/NetworkStats"
import Account from "./Account"
import General from "./General"
import * as style from "./style.module.scss"

const ModalSettings = () => {
  const dispatch = useAppDispatch()
  const modalSettings = useAppSelector((state) => state.app.modalSettings)
  const currentAccount = useAppSelector(AccountSelectors.currentAccountExtended)

  return (
    <Modal
      title={null}
      open={modalSettings}
      onCancel={() => dispatch(AppActions.MODAL_SETTINGS_SET(false))}
      footer={null}
      width={650}
      closeIcon={<span className="xi xi-close" />}
      destroyOnClose
    >
      {currentAccount && (
        <Tabs
          destroyInactiveTabPane
          defaultActiveKey="account"
          items={[
            {
              key: "account",
              label: <strong>Account</strong>,
              children: <Account />,
            },
            {
              key: "general",
              label: <strong>General</strong>,
              children: <General />,
            },
          ]}
        />
      )}
      {!currentAccount && (
        <div>
          <div className="mb-4">
            <strong>General Settings</strong>
          </div>
          <General />
        </div>
      )}
      <div className="xray-line xray-line-dashed mb-4" />
      <div className="mb-1">
        <span className="d-flex align-items-center">
          <span>App Info</span>
          <div className="ms-auto font-size-12 text-muted text-right">
            <NetworkStats />
          </div>
        </span>
      </div>
    </Modal>
  )
}

export default ModalSettings
