import React from "react"
import { Timeline } from "antd"

const Roadmap = () => {
  return (
    <div>
      <h4>2025 and Beyond: Growth Phase</h4>
      <div className="mb-3">
        <Timeline
          items={[
            {
              dot: <i className="xi xi-clock" />,
              children: (
                <div>
                  <strong>Q2 2025: Market Expansion</strong>
                  <div className="font-size-14 text-muted">
                    <ol className="ps-3 mt-2">
                      <li>Mini Apps contest and developer rewards program.</li>
                      <li>Liquidity bootstrapping for XRAY token.</li>
                    </ol>
                  </div>
                </div>
              ),
            },
            {
              dot: <i className="xi xi-clock" />,
              children: (
                <div>
                  <strong>Q1 2025: XRAY/App Functional Release</strong>
                  <div className="font-size-14 text-muted">
                    <ol className="ps-3 mt-2">
                      <li>
                        Enhancing XRAY/App with new features and improvements: Wallet, Stake, Graph, Swap, CardanoList,
                        etc...
                      </li>
                      <li>XRAY/App build versions: App, Browser Extension, Telegram App, Minterr, CardanoList</li>
                      <li>
                        Releasing XRAY/Vault – a secure and user-friendly solution for remotely managing and utilizing
                        Cardano private keys.
                      </li>
                      <li>Release Mini Apps feature for developers to create and deploy their own apps on XRAY/App.</li>
                      <li>Pigz.io: Future Marketing and XCOIN Token Launch.</li>
                      <li>Wiki and documentation for developers and users.</li>
                    </ol>
                  </div>
                </div>
              ),
            },
          ]}
        />
      </div>
      <h4>Through 2024: Establishing Phase</h4>
      <div className="mb-3">
        <Timeline
          items={[
            {
              dot: <i className="xi xi-check_circled text-success" />,
              children: (
                <div>
                  <strong>XRAY/App with Mini Apps</strong>
                  <div className="font-size-14 text-muted">
                    Introducing extensible Mini Apps to expand XRAY/App’s functionality and adaptability.
                  </div>
                </div>
              ),
            },
            {
              dot: <i className="xi xi-check_circled text-success" />,
              children: (
                <div>
                  <strong>Telegram Bots Launch</strong>
                  <div className="font-size-14 text-muted">@xray_app_bot, @xray_vault_bot, @pigz_io_bot</div>
                </div>
              ),
            },
            {
              dot: <i className="xi xi-check_circled text-success" />,
              children: (
                <div>
                  <strong>Pigz.io Telegram Game</strong>
                  <div className="font-size-14 text-muted">
                    Creating a fun, interactive gaming experience powered by the XRAY ecosystem.
                  </div>
                </div>
              ),
            },
            {
              dot: <i className="xi xi-check_circled text-success" />,
              children: (
                <div>
                  <strong>XRAY/Graph APIs</strong>
                  <div className="font-size-14 text-muted">
                    Launching robust APIs to empower developers with blockchain data and analytics.
                  </div>
                </div>
              ),
            },
            {
              dot: <i className="xi xi-check_circled text-success text-success" />,
              children: (
                <div>
                  <strong>CardanoWeb3Js (Project Catalyst Fund 5)</strong>
                  <div className="font-size-14 text-muted">
                    Developing a versatile TypeScript library for seamless Cardano blockchain integration. Delivered.
                  </div>
                </div>
              ),
            },
            {
              dot: <i className="xi xi-check_circled text-success" />,
              children: (
                <div>
                  <strong>XRAY Token Launch: 0% Fee ISPO</strong>
                  <div className="font-size-14 text-muted">
                    Introducing the XRAY token through a fee-free Initial Stake Pool Offering.
                  </div>
                </div>
              ),
            },
            {
              dot: <i className="xi xi-check_circled text-success" />,
              children: (
                <div>
                  <strong>RayWallet V1/V2</strong>
                  <div className="font-size-14 text-muted">
                    Delivering secure wallet solutions in two major iterations.
                  </div>
                </div>
              ),
            },
            {
              dot: <i className="xi xi-check_circled text-success" />,
              children: (
                <div>
                  <strong>Establishing the network</strong>
                  <div className="font-size-14 text-muted">
                    Launching the foundational infrastructure to support the XRAY ecosystem on Cardano.
                  </div>
                </div>
              ),
            },
          ]}
        />
      </div>
    </div>
  )
}

export default Roadmap
