import { ConfigTypes } from "@/config"

/**
 * Account Types
 */

export interface IAccount {
  revision: number
  id: string
  type: "xprv" | "address" | "connector"
  connectorId: string | null
  network: ConfigTypes.Network
  web3export: ConfigTypes.T.AccountExportV1
}

export type IInstance = {
  id: string
  web3account: ConfigTypes.CW3Account | null
}

export type IBalance = {
  id: string
  loading: boolean
  updatedAt: number
  state: ConfigTypes.T.AccountState | null
}

export type ICurrentAccountId = {
  mainnet: string | null
  preprod: string | null
  preview: string | null
}

export type IAccountName = {
  id: string
  type: "address" | "handle" | "custom"
  handle: string
  custom: string
}

export type IAccounyHandle = {
  id: string
  handles: string[]
}

export interface ISettings {
  currentAccountId: ICurrentAccountId
  accounts: IAccount[]
  instances: IInstance[]
  balances: IBalance[]
  names: IAccountName[]
  handles: IAccounyHandle[]
  miniApps: string[]
}

/**
 * Account Actions
 */

export enum Enum {
  CLEAR = "account/CLEAR",

  ACCOUNTS_SET = "account/ACCOUNTS_SET",
  INSTANCES_SET = "account/INSTANCES_SET",
  BALANCES_SET = "account/BALANCES_SET",
  BALANCE_LOADING_SET = "account/BALANCE_LOADING_SET",
  CURRENT_ACCOUNT_ID_SET = "account/CURRENT_ACCOUNT_ID_SET",
  NAMES_SET = "account/NAMES_SET",
  HANDLES_SET = "account/HANDLES_SET",
  MINI_APPS_SET = "account/MINI_APPS_SET",

  ADD_ACCOUNT_SAGA = "account/ADD_ACCOUNT_SAGA",
  DELETE_ACCOUNT_SAGA = "account/DELETE_ACCOUNT_SAGA",
  UPDATE_INSTANCE_SAGA = "account/UPDATE_INSTANCE_SAGA",
  INIT_INSTANCES_SAGA = "account/INIT_INSTANCES_SAGA",

  ADD_BROWSER_CONNECTOR_SAGA = "account/ADD_BROWSER_CONNECTOR_SAGA",
  INIT_BROWSER_CONNECTOR_SAGA = "account/INIT_BROWSER_CONNECTOR_SAGA",
  ADD_XPRV_KEY_SAGA = "account/ADD_XPRV_KEY_SAGA",
  INIT_XPRV_KEY_SAGA = "account/INIT_XPRV_KEY_SAGA",
  ADD_READ_ONLY_SAGA = "account/ADD_READ_ONLY_SAGA",
  INIT_READ_ONLY_SAGA = "account/INIT_READ_ONLY_SAGA",
  UPDATE_BALANCE_SAGA = "account/UPDATE_BALANCE_SAGA",
  CURRENT_ACCOUNT_ID_UPDATE_SAGA = "account/CURRENT_ACCOUNT_ID_UPDATE_SAGA",
  NAME_UPDATE_SAGA = "account/NAME_UPDATE_SAGA",
  HANDLES_UPDATE_SAGA = "account/HANDLES_UPDATE_SAGA",
  MINI_APPS_INSTALL_SAGA = "account/MINI_APPS_INSTALL_SAGA",
  MINI_APPS_UNINSTALL_SAGA = "account/MINI_APPS_UNINSTALL_SAGA",
}

export type AClear = {
  type: typeof Enum.CLEAR
}

export type AAccountsSet = {
  type: typeof Enum.ACCOUNTS_SET
  accounts: IAccount[]
}

export type AInstancesSet = {
  type: typeof Enum.INSTANCES_SET
  instances: IInstance[]
}

export type ABalancesSet = {
  type: typeof Enum.BALANCES_SET
  balances: IBalance[]
}

export type ABalanceLoadingSet = {
  type: typeof Enum.BALANCE_LOADING_SET
  id: string
  loading: boolean
}

export type ACurrentAccountIdSet = {
  type: typeof Enum.CURRENT_ACCOUNT_ID_SET
  currentAccountId: ICurrentAccountId
}

export type ANamesSet = {
  type: typeof Enum.NAMES_SET
  names: IAccountName[]
}

export type AHandlesSet = {
  type: typeof Enum.HANDLES_SET
  handles: IAccounyHandle[]
}

export type AMiniAppsSet = {
  type: typeof Enum.MINI_APPS_SET
  miniApps: string[]
}

export type ANameUpdateSaga = {
  type: typeof Enum.NAME_UPDATE_SAGA
  name: IAccountName
}

export type AAddAccountSaga = {
  type: typeof Enum.ADD_ACCOUNT_SAGA
  account: IAccount
}

export type ADeleteAccountSaga = {
  type: typeof Enum.DELETE_ACCOUNT_SAGA
  id: string
}

export type AUpdateInstanceSaga = {
  type: typeof Enum.UPDATE_INSTANCE_SAGA
  instance: IInstance
}

export type AInitInstancesSaga = {
  type: typeof Enum.INIT_INSTANCES_SAGA
}

export type AAddBrowserConnectorSaga = {
  type: typeof Enum.ADD_BROWSER_CONNECTOR_SAGA
  wallet: string
}

export type AInitBrowserConnectorSaga = {
  type: typeof Enum.INIT_BROWSER_CONNECTOR_SAGA
  account: IAccount
}

export type AAddXprvKeySaga = {
  type: typeof Enum.ADD_XPRV_KEY_SAGA
  xprvKey: string
  password: string
}

export type AInitXprvKeySaga = {
  type: typeof Enum.INIT_XPRV_KEY_SAGA
  account: IAccount
}

export type AAddReadOnlySaga = {
  type: typeof Enum.ADD_READ_ONLY_SAGA
  address: string
}

export type AInitReadOnlySaga = {
  type: typeof Enum.INIT_READ_ONLY_SAGA
  account: IAccount
}

export type AUpdateBalanceSaga = {
  type: typeof Enum.UPDATE_BALANCE_SAGA
  id: string
  silent: boolean
}

export type ACurrentAccountIdUpdateSaga = {
  type: typeof Enum.CURRENT_ACCOUNT_ID_UPDATE_SAGA
  id: string | null
}

export type AHandlesUpdateSaga = {
  type: typeof Enum.HANDLES_UPDATE_SAGA
  handle: IAccounyHandle
}

export type AMiniAppsInstallSaga = {
  type: typeof Enum.MINI_APPS_INSTALL_SAGA
  id: string
}

export type AMiniAppsUninstallSaga = {
  type: typeof Enum.MINI_APPS_UNINSTALL_SAGA
  id: string
}

export type Actions =
  | AClear
  | AAccountsSet
  | AInstancesSet
  | ABalancesSet
  | ABalanceLoadingSet
  | ACurrentAccountIdSet
  | ANamesSet
  | AHandlesSet
  | AMiniAppsSet
  | ANameUpdateSaga
  | AAddAccountSaga
  | ADeleteAccountSaga
  | AUpdateInstanceSaga
  | AInitInstancesSaga
  | AAddBrowserConnectorSaga
  | AInitBrowserConnectorSaga
  | AAddXprvKeySaga
  | AInitXprvKeySaga
  | AAddReadOnlySaga
  | AInitReadOnlySaga
  | AUpdateBalanceSaga
  | ACurrentAccountIdUpdateSaga
  | AHandlesUpdateSaga
  | AMiniAppsInstallSaga
  | AMiniAppsUninstallSaga
