import { ConfigTypes } from "@/config"
import { TransactionTypes } from "."

export function CLEAR(): TransactionTypes.AClear {
  return { type: TransactionTypes.Enum.CLEAR }
}

export function MODAL_TRANSACTION_SET(
  tx: string,
  kind: string,
  outputs?: ConfigTypes.T.Output[]
): TransactionTypes.ATransactionSet {
  return { type: TransactionTypes.Enum.TRANSACTION_SET, tx, kind, outputs }
}

export function PENDING_TXS_SET(pendingTxs: TransactionTypes.PendingTxs): TransactionTypes.APendingTxsSet {
  return { type: TransactionTypes.Enum.PENDING_TXS_SET, pendingTxs }
}

export function PENDING_TX_UPDATE_SAGA(hash: string): TransactionTypes.APendingTxsUpdateSaga {
  return { type: TransactionTypes.Enum.PENDING_TX_UPDATE_SAGA, hash }
}

export function PENDING_TX_REMOVE_SAGA(): TransactionTypes.APendingTxsRemoveSaga {
  return { type: TransactionTypes.Enum.PENDING_TX_REMOVE_SAGA }
}

export function PENDING_TXS_CHECK_SAGA(): TransactionTypes.APendingTxsCheckSaga {
  return { type: TransactionTypes.Enum.PENDING_TXS_CHECK_SAGA }
}
