import { all, takeEvery, put, call, select, fork, takeLatest, delay, cancelled } from "redux-saga/effects"
import { MetadataActions, MetadataTypes } from "."
import { AccountActions, AccountTypes } from "@/redux/account"
import { typedSelect, typedCall } from "@/redux/provider"
import { getFiatPrice } from "@/services/fiatPrice"
import { CardanoWeb3 } from "cardano-web3-js"

// export function* TEMP() {
// }

export default function* rootSaga() {
  yield all([])
}
