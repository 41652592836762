import React, { useEffect, useState } from "react"
import MiniAppHeader from "@/components/common/MiniAppHeader"
import * as style from "./style.module.scss"

const WalletLayout = ({ children, location }: { children: React.ReactNode; location: any }) => {
  return (
    <div>
      <MiniAppHeader id="xray-wallet" />
      <div className="xray-page">{children}</div>
    </div>
  )
}

export default WalletLayout
