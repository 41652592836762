import { TransactionTypes } from "."
import { getPersistSettings, setPersistSetting } from "@/services/localstorage"
import { LOCAL_STORAGE_PREFIX_GLOBAL } from "@/config"

const LOCAL_STORAGE_PREFIX = LOCAL_STORAGE_PREFIX_GLOBAL + "transaction."
const setLocalstorage = (key: string, value: any) => {
  setPersistSetting(LOCAL_STORAGE_PREFIX, key, value)
}

const initialState: TransactionTypes.ISettings = {
  pendingTxs: {},
  transaction: undefined,
}

export default (
  state: TransactionTypes.ISettings = { ...getPersistSettings(LOCAL_STORAGE_PREFIX, initialState) },
  action: TransactionTypes.Actions
): TransactionTypes.ISettings => {
  switch (action.type) {
    case TransactionTypes.Enum.CLEAR:
      return initialState

    case TransactionTypes.Enum.TRANSACTION_SET:
      return {
        ...state,
        transaction: {
          tx: action.tx,
          kind: action.kind,
          outputs: action.outputs,
        },
      }

    case TransactionTypes.Enum.PENDING_TXS_SET:
      setLocalstorage("pendingTxs", action.pendingTxs)
      return {
        ...state,
        pendingTxs: action.pendingTxs,
      }

    default:
      return state
  }
}
