import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { Modal, Button, Collapse, Tag, Alert } from "antd"
import classnames from "classnames"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import { SVGCardano } from "@/svg"
import * as style from "./style.module.scss"
import { AppActions } from "@/redux/app"
import { browserConnectors, browserConnectorData, ConfigTypes } from "@/config"
import { AccountActions, AccountSelectors } from "@/redux/account"
import * as Utils from "@/utils"
import AccountsList from "./AccountsList"
import Vault from "./Vault"
import BrowserConnectors from "./BrowserConnectors"
import PrivateKey from "./PrivateKey"
import HardwareWallets from "./HardwareWallets"
import Readonly from "./Readonly"
import ImportAccount from "./ImportAccount"
import Informers from "@/components/informers"

const ModalAccount = () => {
  const dispatch = useAppDispatch()
  const web3 = useAppSelector((state) => state.app.web3.instance)
  const modalAccount = useAppSelector((state) => state.app.modalAccount)
  const hideAddAccounts = useAppSelector((state) => state.app.hideAddAccounts)
  const hideAddAccountSuccess = useAppSelector((state) => state.app.hideAddAccountSuccess)
  const accounts = useAppSelector(AccountSelectors.accountsExtended)
  const currentAccount = useAppSelector(AccountSelectors.currentAccountExtended)
  const [isActive, setIsActive] = useState(false)

  return (
    <Modal
      title={null}
      open={modalAccount}
      onCancel={() => {
        setIsActive(false)
        dispatch(AppActions.HIDE_ADD_ACCOUNTS_SET(true))
        dispatch(AppActions.HIDE_ADD_ACCOUNT_SUCCESS_SET(true))
        dispatch(AppActions.MODAL_ACCOUNT_SET(false))
      }}
      footer={null}
      width={650}
      closeIcon={<span className="xi xi-close" />}
      destroyOnClose={true}
    >
      <div className="mb-4">
        <AccountsList />
      </div>
      {!hideAddAccountSuccess && (
        <Alert
          description="Now you can use your account to interact with the Cardano ecosystem"
          message="Account added successfully"
          type="success"
          showIcon
          className="mb-4"
          closable
          onClose={() => dispatch(AppActions.HIDE_ADD_ACCOUNT_SUCCESS_SET(true))}
        />
      )}
      {currentAccount && (
        <div>
          <div className="xray-line xray-line-dashed mb-4" />
          <div className="overflow-hidden">
            <div className="d-flex align-items-center flex-wrap me--5 mb-2">
              <div className="me-5 mb-3 font-size-16">
                <Informers.Ada
                  quantity={currentAccount.state?.balance.value || "0"}
                  title="Balance"
                  help="Current address balance"
                  sameSize
                />
              </div>
              <div className="me-5 mb-3 font-size-16">
                <Informers.Ada
                  quantity={currentAccount.state?.rewards || "0"}
                  title="Rewards"
                  help="Rewards available for withdrawal"
                  sameSize
                />
              </div>
              <div className="me-5 mb-3 font-size-16">
                <Informers.Text
                  value={Utils.truncate(currentAccount.web3export.paymentAddress)}
                  title="Address"
                  help="Your account main payment address"
                  copy={currentAccount.web3export.paymentAddress}
                />
              </div>
              <div className="me-5 mb-3 font-size-16">
                <Informers.Text
                  value={
                    currentAccount.web3export.stakingAddress
                      ? Utils.truncate(currentAccount.web3export.stakingAddress)
                      : "—"
                  }
                  title="Staking Address"
                  help="Your account staking address"
                  copy={currentAccount.web3export.stakingAddress}
                />
              </div>
              <div className="me-5 mb-3 font-size-16">
                <Informers.Text
                  value={currentAccount.state?.delegation ? Utils.truncate(currentAccount.state.delegation) : "—"}
                  title="Delegation Pool"
                  help="Delegated staking pool"
                  copy={currentAccount.state?.delegation}
                />
              </div>
              {/* <div className="me-5 mb-4 font-size-21">
                <Informers.Text value="addr1...124125" title="Delegation DRep" />
              </div> */}
            </div>
          </div>
        </div>
      )}
      {hideAddAccounts && accounts.length > 0 && (
        <div className="pb-2">
          <div className="xray-line xray-line-dashed mb-4" />
          <Button
            shape="round"
            className="w-100p"
            size="large"
            onClick={() => {
              setIsActive(false)
              dispatch(AppActions.HIDE_ADD_ACCOUNT_SUCCESS_SET(true))
              dispatch(AppActions.HIDE_ADD_ACCOUNTS_SET(false))
            }}
          >
            <strong>Add Another Account</strong>
            <i className="xi xi-chevron_down font-size-12" />
          </Button>
        </div>
      )}
      {!(hideAddAccounts && accounts.length > 0) && (
        <div>
          <Collapse
            destroyInactivePanel
            // defaultActiveKey={["xprvKey"]}
            onChange={(key) => setIsActive(key.length > 0)}
            className={classnames(style.collapse, { [style.collapseActive]: isActive })}
            expandIcon={({ isActive }) => <i className={`xi xi-chevron_${isActive ? "up" : "down"} me-2`} />}
            accordion
            ghost
            items={[
              {
                key: "vault",
                label: (
                  <span>
                    <span className="me-2">XRAY/Vault Connect</span>
                    <Tag color="default">soon</Tag>
                  </span>
                ),
                children: <Vault />,
              },
              {
                key: "browserConnectors",
                label: "Browser Connectors",
                children: <BrowserConnectors />,
              },
              {
                key: "privateKey",
                label: "Mnemonic or Private Key",
                children: <PrivateKey />,
              },
              {
                key: "hardwareWallets",
                label: (
                  <span>
                    <span className="me-2">Hardware Wallets</span>
                    <Tag color="default">soon</Tag>
                  </span>
                ),
                children: <HardwareWallets />,
              },
              {
                key: "readonly",
                label: "Read-Only Connect",
                children: <Readonly />,
              },
              {
                key: "importAccount",
                label: "Import Key File",
                children: <ImportAccount />,
              },
            ]}
          />
        </div>
      )}
      <div className="mt-4 font-size-14 text-muted">
        <span>By connecting a wallet, you agree to the</span>{" "}
        <strong>
          <Link to="/wiki/terms-of-service" onClick={() => dispatch(AppActions.MODAL_ACCOUNT_SET(false))}>
            Terms of Service
          </Link>{" "}
          &{" "}
          <Link to="/wiki/privacy-policy" onClick={() => dispatch(AppActions.MODAL_ACCOUNT_SET(false))}>
            Privacy Policy
          </Link>
        </strong>
      </div>
      <div className="mt-2 font-size-14 text-muted">
        <span className="me-3">
          Powered with
          <a href="https://cardano.org" target="_blank" className={style.cardano}>
            <SVGCardano />
            <strong>Cardano</strong>
          </a>
        </span>
        {accounts.length > 0 && (
          <span>
            Need help?{" "}
            <strong>
              <Link to="/wiki" onClick={() => dispatch(AppActions.MODAL_ACCOUNT_SET(false))}>
                Read our Wiki
              </Link>
            </strong>
          </span>
        )}
      </div>
    </Modal>
  )
}

export default ModalAccount
