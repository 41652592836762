import { AppTypes } from "../app"
import { AccountTypes } from "./"
import { ConfigTypes } from "@/config"

export function CLEAR(): AccountTypes.AClear {
  return { type: AccountTypes.Enum.CLEAR }
}

export function ACCOUNTS_SET(accounts: AccountTypes.IAccount[]): AccountTypes.AAccountsSet {
  return { type: AccountTypes.Enum.ACCOUNTS_SET, accounts }
}

export function INSTANCES_SET(instances: AccountTypes.IInstance[]): AccountTypes.AInstancesSet {
  return { type: AccountTypes.Enum.INSTANCES_SET, instances }
}

export function BALANCES_SET(balances: AccountTypes.IBalance[]): AccountTypes.ABalancesSet {
  return { type: AccountTypes.Enum.BALANCES_SET, balances }
}

export function BALANCE_LOADING_SET(id: string, loading: boolean): AccountTypes.ABalanceLoadingSet {
  return { type: AccountTypes.Enum.BALANCE_LOADING_SET, id, loading }
}

export function CURRENT_ACCOUNT_ID_SET(
  currentAccountId: AccountTypes.ICurrentAccountId
): AccountTypes.ACurrentAccountIdSet {
  return { type: AccountTypes.Enum.CURRENT_ACCOUNT_ID_SET, currentAccountId }
}

export function NAMES_SET(names: AccountTypes.IAccountName[]): AccountTypes.ANamesSet {
  return { type: AccountTypes.Enum.NAMES_SET, names }
}

export function HANDLES_SET(handles: AccountTypes.IAccounyHandle[]): AccountTypes.AHandlesSet {
  return { type: AccountTypes.Enum.HANDLES_SET, handles }
}

export function MINI_APPS_SET(miniApps: string[]): AccountTypes.AMiniAppsSet {
  return { type: AccountTypes.Enum.MINI_APPS_SET, miniApps }
}

export function NAME_UPDATE_SAGA(name: AccountTypes.IAccountName): AccountTypes.ANameUpdateSaga {
  return { type: AccountTypes.Enum.NAME_UPDATE_SAGA, name }
}

export function ADD_ACCOUNT_SAGA(account: AccountTypes.IAccount): AccountTypes.AAddAccountSaga {
  return { type: AccountTypes.Enum.ADD_ACCOUNT_SAGA, account }
}

export function DELETE_ACCOUNT_SAGA(id: string): AccountTypes.ADeleteAccountSaga {
  return { type: AccountTypes.Enum.DELETE_ACCOUNT_SAGA, id }
}

export function UPDATE_INSTANCE_SAGA(instance: AccountTypes.IInstance): AccountTypes.AUpdateInstanceSaga {
  return { type: AccountTypes.Enum.UPDATE_INSTANCE_SAGA, instance }
}

export function INIT_INSTANCES_SAGA(): AccountTypes.AInitInstancesSaga {
  return { type: AccountTypes.Enum.INIT_INSTANCES_SAGA }
}

export function ADD_BROWSER_CONNECTOR_SAGA(wallet: string): AccountTypes.AAddBrowserConnectorSaga {
  return { type: AccountTypes.Enum.ADD_BROWSER_CONNECTOR_SAGA, wallet }
}

export function INIT_BROWSER_CONNECTOR_SAGA(account: AccountTypes.IAccount): AccountTypes.AInitBrowserConnectorSaga {
  return { type: AccountTypes.Enum.INIT_BROWSER_CONNECTOR_SAGA, account }
}

export function ADD_XPRV_KEY_SAGA(xprvKey: string, password: string): AccountTypes.AAddXprvKeySaga {
  return { type: AccountTypes.Enum.ADD_XPRV_KEY_SAGA, xprvKey, password }
}

export function INIT_XPRV_KEY_SAGA(account: AccountTypes.IAccount): AccountTypes.AInitXprvKeySaga {
  return { type: AccountTypes.Enum.INIT_XPRV_KEY_SAGA, account }
}

export function ADD_READ_ONLY_SAGA(address: string): AccountTypes.AAddReadOnlySaga {
  return { type: AccountTypes.Enum.ADD_READ_ONLY_SAGA, address }
}

export function INIT_READ_ONLY_SAGA(account: AccountTypes.IAccount): AccountTypes.AInitReadOnlySaga {
  return { type: AccountTypes.Enum.INIT_READ_ONLY_SAGA, account }
}

export function UPDATE_BALANCE_SAGA(id: string, silent: boolean): AccountTypes.AUpdateBalanceSaga {
  return { type: AccountTypes.Enum.UPDATE_BALANCE_SAGA, id, silent }
}

export function CURRENT_ACCOUNT_ID_UPDATE_SAGA(id: string | null): AccountTypes.ACurrentAccountIdUpdateSaga {
  return { type: AccountTypes.Enum.CURRENT_ACCOUNT_ID_UPDATE_SAGA, id }
}

export function HANDLES_UPDATE_SAGA(handle: AccountTypes.IAccounyHandle): AccountTypes.AHandlesUpdateSaga {
  return { type: AccountTypes.Enum.HANDLES_UPDATE_SAGA, handle }
}

export function MINI_APPS_INSTALL_SAGA(id: string): AccountTypes.AMiniAppsInstallSaga {
  return { type: AccountTypes.Enum.MINI_APPS_INSTALL_SAGA, id }
}

export function MINI_APPS_UNINSTALL_SAGA(id: string): AccountTypes.AMiniAppsUninstallSaga {
  return { type: AccountTypes.Enum.MINI_APPS_UNINSTALL_SAGA, id }
}
