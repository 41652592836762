import { RootState, useAppSelector } from "../provider"
import { createSelector } from "@reduxjs/toolkit"
import { AccountSelectors } from "@/redux/account"

export const currentAccountPendingTx = createSelector(
  [(state: RootState) => state.transaction.pendingTxs, AccountSelectors.currentAccountId],
  (pendingTxs, currentAccountId) => {
    return currentAccountId ? pendingTxs?.[currentAccountId] : undefined
  }
)
