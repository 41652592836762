import React, { useEffect, useState } from "react"
import { LoadingOutlined } from "@ant-design/icons"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import { TransactionActions, TransactionSelectors, TransactionTypes } from "@/redux/transaction"
import LinkExplorer from "@/components/common/LinkExplorer"

const Pending = () => {
  const currentAccountPendingTx = useAppSelector(TransactionSelectors.currentAccountPendingTx)

  return (
    <div className="mt-4 mb-4 pb-2">
      <div className="text-center text-blue mb-3">
        <LoadingOutlined style={{ fontSize: 72 }} spin />
      </div>
      <div className="text-center mt-2 font-size-24">
        <strong>Waiting Tx..</strong>
      </div>
      <div className="text-center text-muted mt-2 mb-4">
        <LinkExplorer
          title={currentAccountPendingTx?.hash || ""}
          link={currentAccountPendingTx?.hash || ""}
          type="transaction"
        />
      </div>
      <div className="text-center w-max-400 text-muted font-size-14 mx-auto">
        The current (or previous) transaction is queued and will be added to the blockchain soon. You can close this
        window, it will not stop the transaction from being sent.
      </div>
    </div>
  )
}

export default Pending
