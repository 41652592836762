import React, { useEffect, useState } from "react"
import { message, notification, Upload, UploadProps } from "antd"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import { AccountActions, AccountSelectors } from "@/redux/account"

const ImportAccount = () => {
  const dispatch = useAppDispatch()

  const props: UploadProps = {
    name: "file",
    showUploadList: false,
    beforeUpload(file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        try {
          const data = JSON.parse((e.target as any).result)
          if (data.revision) {
            dispatch(AccountActions.ADD_ACCOUNT_SAGA(data))
          } else {
            // message.error("Wrong .key file")
            notification.error({
              message: "Wrong .key file",
              description: "Please upload a correct .key file",
            })
          }
        } catch {
          notification.error({
            message: "Wrong .key file",
            description: "Please upload a correct .key file",
          })
        }
      }
      reader.readAsText(file)
      return false
    },
  }

  return (
    <div>
      <div>
        <Upload.Dragger {...props}>
          <p className="ant-upload-drag-icon pt-3">
            <i className="xi xi-add_circled font-size-46" />
          </p>
          <p className="font-size-16 mb-0">Click or drag .key file to this area to upload</p>
          <p className="font-size-14 text-muted">Your wallet will be added immediately</p>
        </Upload.Dragger>
      </div>
    </div>
  )
}

export default ImportAccount
