import React, { useEffect, useState } from "react"
import classnames from "classnames"
import { MDXProvider } from "@mdx-js/react"
import { Helmet } from "react-helmet"
import Menu from "@/components/pages/Wiki/Menu"
import * as style from "./style.module.scss"
import Empty from "@/components/common/Empty"
import Line from "@/components/pages/Wiki/Line"
import Roadmap from "@/components/pages/Wiki/Roadmap"
import Contents from "@/components/pages/Wiki/Contents"

const WikiLayout = ({ children, location }: { children: React.ReactNode; location: any }) => {
  return (
    <div className="xray-page">
      <Helmet title="Wiki" />
      <MDXProvider components={{ Empty, Line, Roadmap, Contents }}>
        <div className={style.layout}>
          <div className={style.menu}>
            <Menu />
          </div>
          <div className={style.body}>
            <div className="wiki">{children}</div>
          </div>
        </div>
      </MDXProvider>
    </div>
  )
}

export default WikiLayout
