import React, { useEffect, useState } from "react"
import { Modal, Tabs } from "antd"
import { navigate } from "gatsby"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import { AppActions, AppTypes } from "@/redux/app"
import { AccountActions, AccountSelectors, AccountTypes } from "@/redux/account"
import { TransactionActions, TransactionSelectors } from "@/redux/transaction"
import Sign from "./Sign"
import Pending from "./Pending"
import Success from "./Success"
import Readonly from "./Readonly"
import * as style from "./style.module.scss"
import { has } from "lodash"

const ModalSettings = () => {
  const dispatch = useAppDispatch()
  const transaction = useAppSelector((state) => state.transaction.transaction)
  const currentAccountPendingTx = useAppSelector(TransactionSelectors.currentAccountPendingTx)

  const pending = !!currentAccountPendingTx
  const success = !!currentAccountPendingTx?.seenOnBlockchain

  return (
    <Modal
      title={null}
      open={!!transaction?.tx}
      onCancel={() => dispatch(TransactionActions.MODAL_TRANSACTION_SET("", ""))}
      footer={null}
      width={650}
      closeIcon={<span className="xi xi-close" />}
      destroyOnClose
    >
      {transaction?.kind === "readonly" && (
        <>
          <Readonly />
        </>
      )}
      {transaction?.kind === "send" && (
        <>
          {!pending && <Sign />}
          {pending && (
            <>
              {success && <Success />}
              {!success && <Pending />}
            </>
          )}
        </>
      )}
    </Modal>
  )
}

export default ModalSettings
