import { RootState, useAppSelector } from "../provider"
import { createSelector } from "@reduxjs/toolkit"
import { AccountTypes } from "@/redux/account"
import { AppTypes } from "@/redux/app"

export const currentAccountId = createSelector(
  [(state: RootState) => state.account.currentAccountId, (state: RootState) => state.app.network],
  (currentAccountId, network) => {
    return currentAccountId[network.name]
  }
)

export const currentAccount = createSelector(
  [
    (state: RootState) => state.account.accounts,
    (state: RootState) => state.account.currentAccountId,
    (state: RootState) => state.app.network,
  ],
  (accounts, currentAccountId, network) => {
    const account = accounts.find(
      (account) => account.id === currentAccountId[network.name] && account.network.name === network.name
    )
    if (!account) return
    return account
  }
)

export const currentAccountExtended = createSelector(
  [
    (state: RootState) => state.account.accounts,
    (state: RootState) => state.account.instances,
    (state: RootState) => state.account.balances,
    (state: RootState) => state.account.currentAccountId,
    (state: RootState) => state.account.names,
    (state: RootState) => state.account.handles,
    (state: RootState) => state.app.connectorsOnline,
    (state: RootState) => state.app.network,
  ],
  (accounts, instances, balances, currentAccountId, names, handles, connectorsOnline, network) => {
    const account = accounts.find(
      (account) => account.id === currentAccountId[network.name] && account.network.name === network.name
    )
    if (!account) return
    const instance = instances.find((i) => i.id === account.id)
    const balance = balances.find((b) => b.id === account.id)
    const handle = handles.find((b) => b.id === account.id)
    return {
      ...account,
      ...instance,
      ...balance,
      handles: handle?.handles || [],
      name: detectName(account, names),
      canSign: detectCanSign(account, connectorsOnline),
    }
  }
)

export const accounts = createSelector(
  [(state: RootState) => state.account.accounts, (state: RootState) => state.app.network],
  (accounts, network) => {
    return accounts.filter((a) => a.network.name === network.name)
  }
)

export const accountsExtended = createSelector(
  [
    (state: RootState) => state.account.accounts,
    (state: RootState) => state.account.instances,
    (state: RootState) => state.account.balances,
    (state: RootState) => state.account.names,
    (state: RootState) => state.account.handles,
    (state: RootState) => state.app.connectorsOnline,
    (state: RootState) => state.app.network,
  ],
  (accounts, instances, balances, names, handles, connectorsOnline, network) => {
    return accounts
      .map((account) => {
        const instance = instances.find((i) => i.id === account.id)
        const balance = balances.find((b) => b.id === account.id)
        const handle = handles.find((b) => b.id === account.id)
        return {
          ...account,
          ...instance,
          ...balance,
          handles: handle?.handles || [],
          name: detectName(account, names),
          canSign: detectCanSign(account, connectorsOnline),
        }
      })
      .filter((a) => a.network.name === network.name)
  }
)

const detectName = (account: AccountTypes.IAccount, names: AccountTypes.IAccountName[]): AccountTypes.IAccountName => {
  const name = names.find((n) => n.id === account.id)
  return name || { id: account.id, type: "address", handle: "", custom: "" }
}

const detectCanSign = (account: AccountTypes.IAccount, connectorsOnline: AppTypes.ConnectorOnline[]) => {
  let canSign: boolean = false

  if (
    account.type === "connector" &&
    connectorsOnline.find((c) => c.id === account?.connectorId && c.address === account?.web3export?.paymentAddress)
  ) {
    canSign = true
  }

  if (account.type === "xprv") {
    canSign = true
  }

  return canSign
}
