import React, { useEffect, useState } from "react"
import { Tooltip } from "antd"
import Jazzicon from "react-jazzicon"
import classnames from "classnames"
import { useAppSelector, useAppDispatch } from "@/redux/provider"
import { AccountActions, AccountTypes } from "@/redux/account"
import * as utils from "@/utils"
import Blockies from "@/utils/blockies"
import { SVGAdaHandle } from "@/svg"
import * as style from "./style.module.scss"

const Avatar = ({ icon, loading, sending }: { icon: string; loading: boolean; sending?: boolean }) => {
  return (
    <div>
      <div className={style.avatar}>
        <div
          className={classnames(style.avatarLoading, {
            [style.avatarLoadingActive]: loading,
          })}
        >
          <i className="xray-spinner xray-spinner-md" />
        </div>
        {sending && (
          <span className={style.avatarSending}>
            <i className="xi xi-arrow_up" />
          </span>
        )}
        {/* <Blockies address={icon} />  */}
        <Jazzicon diameter={28} seed={utils.numberFromString(icon)} />
      </div>
    </div>
  )
}

export default Avatar
