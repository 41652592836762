import { AppTypes } from "."
import { ConfigTypes } from "@/config"
import { FiatPriceResponse } from "@/services/fiatPrice"

export function CLEAR(): AppTypes.AClear {
  return { type: AppTypes.Enum.CLEAR }
}

export function INIT_APP(): AppTypes.AInitApp {
  return { type: AppTypes.Enum.INIT_APP }
}

export function LOCK_SET(locked: boolean): AppTypes.ALockSet {
  return { type: AppTypes.Enum.LOCK_SET, locked }
}

export function LOADED_SET(loaded: boolean): AppTypes.ALoadedSet {
  return { type: AppTypes.Enum.LOADED_SET, loaded }
}

export function COOKIES_VIEWED_SET(cookiesViewed: boolean): AppTypes.ACookiesViewedSet {
  return { type: AppTypes.Enum.COOKIES_VIEWED_SET, cookiesViewed }
}

export function THEME_SET(theme: AppTypes.Theme): AppTypes.AThemeSet {
  return { type: AppTypes.Enum.THEME_SET, theme }
}

export function SCALE_SET(scale: AppTypes.Scale): AppTypes.AScaleSet {
  return { type: AppTypes.Enum.SCALE_SET, scale }
}

export function MODAL_ACCOUNT_SET(modalAccount: boolean): AppTypes.AModalAccountSet {
  return { type: AppTypes.Enum.MODAL_ACCOUNT_SET, modalAccount }
}

export function MODAL_SETTINGS_SET(modalSettings: boolean): AppTypes.AModalSettingsSet {
  return { type: AppTypes.Enum.MODAL_SETTINGS_SET, modalSettings }
}

export function NETWORK_SET(network: ConfigTypes.Network): AppTypes.ANetworkSet {
  return { type: AppTypes.Enum.NETWORK_SET, network }
}

export function WEB3_SET(web3: ConfigTypes.CardanoWeb3): AppTypes.AWeb3Set {
  return { type: AppTypes.Enum.WEB3_SET, web3 }
}

export function CONNECTORS_ONLINE_SET(connectorsOnline: AppTypes.ConnectorOnline[]): AppTypes.AConnectorsOnlineSet {
  return { type: AppTypes.Enum.CONNECTORS_ONLINE_SET, connectorsOnline }
}

export function TIP_SET(tip: ConfigTypes.T.Tip): AppTypes.ATipSet {
  return { type: AppTypes.Enum.TIP_SET, tip }
}

export function CURRENCY_SET(currency: AppTypes.Currency): AppTypes.ACurrencySet {
  return { type: AppTypes.Enum.CURRENCY_SET, currency }
}

export function HIDE_BALANCES_SET(hideBalances: boolean): AppTypes.AHideBalancesSet {
  return { type: AppTypes.Enum.HIDE_BALANCES_SET, hideBalances }
}

export function EXPLORER_SET(explorer: AppTypes.Explorer): AppTypes.AExplorerSet {
  return { type: AppTypes.Enum.EXPLORER_SET, explorer }
}

export function HIDE_WELCOME_SET(hideWelcome: boolean): AppTypes.AHideWelcomeSet {
  return { type: AppTypes.Enum.HIDE_WELCOME_SET, hideWelcome }
}

export function HIDE_ADD_ACCOUNTS_SET(hideAddAccounts: boolean): AppTypes.AHideAddAccountsSet {
  return { type: AppTypes.Enum.HIDE_ADD_ACCOUNTS_SET, hideAddAccounts }
}

export function HIDE_ADD_ACCOUNT_SUCCESS_SET(hideAddAccountSuccess: boolean): AppTypes.AHideAddAccountSuccessSet {
  return { type: AppTypes.Enum.HIDE_ADD_ACCOUNT_SUCCESS_SET, hideAddAccountSuccess }
}

export function FIAT_PRICE_SET(fiatPrice: FiatPriceResponse): AppTypes.AFiatPriceSet {
  return { type: AppTypes.Enum.FIAT_PRICE_SET, fiatPrice }
}

export function NAVIGATE_AFTER_CLOSE_SET(
  navigateAfterClose: AppTypes.NavigateAfterClose
): AppTypes.ANavigateAfterCloseSet {
  return { type: AppTypes.Enum.NAVIGATE_AFTER_CLOSE_SET, navigateAfterClose }
}

export function WEB3_INIT_SAGA(networkName: ConfigTypes.NetworkName): AppTypes.AWeb3InitSaga {
  return { type: AppTypes.Enum.WEB3_INIT_SAGA, networkName }
}

export function CONNECTORS_ONLINE_UPDATE_SAGA(): AppTypes.AConnectorsOnlineUpdateSaga {
  return { type: AppTypes.Enum.CONNECTORS_ONLINE_UPDATE_SAGA }
}

export function NETWORK_UPDATE_SAGA(networkName: ConfigTypes.NetworkName): AppTypes.ANetworkUpdateSaga {
  return { type: AppTypes.Enum.NETWORK_UPDATE_SAGA, networkName }
}

export function TIP_UPDATE_SAGA(): AppTypes.ATipUpdateSaga {
  return { type: AppTypes.Enum.TIP_UPDATE_SAGA }
}

export function FIAT_PRICE_UPDATE_SAGA(): AppTypes.AFiatPriceUpdateSaga {
  return { type: AppTypes.Enum.FIAT_PRICE_UPDATE_SAGA }
}
