import React from "react"
import { Tooltip } from "antd"
import { quantityFormat, quantityWithCommas } from "@/utils"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import classnames from "classnames"
import * as style from "./style.module.scss"
// import config from "@/config/common"

const InformerAda = ({
  quantity,
  title,
  help,
  hideDecimals,
  shortened,
  hideable,
  tooltip,
  hideTooltip,
  skipZero,
  sameSize,
  prefix,
}: {
  quantity: string | bigint
  title?: string
  tokenName?: string
  help?: string | React.ReactNode
  hideDecimals?: boolean
  shortened?: boolean
  hideable?: boolean
  tooltip?: React.ReactNode | string
  hideTooltip?: boolean
  skipZero?: boolean
  sameSize?: boolean
  prefix?: string
}) => {
  // const hideBalance = useAppSelector((state) => state.settings.hideBalance)
  // const appCurrency = useAppSelector((state) => state.settings.currency)
  // const exchangeRates = useAppSelector((state) => state.network.exchangeRates)

  const { a, b, final } = quantityFormat(quantity, 6, skipZero)
  const short =
    Intl.NumberFormat("en", { notation: "compact", maximumFractionDigits: 2 }).format(
      Number(final.replaceAll(",", ""))
    ) || "0"

  // const amountUSD = amountWithCommas(
  //   ((Number(amount) / 1000000) * (exchangeRates?.cardano?.[appCurrency] || 0)).toFixed(2)
  // )

  return (
    <div
      className={classnames(style.informer, {
        [style.informerSameSize]: sameSize,
      })}
    >
      <div className={style.body}>
        {/* {hideBalance && "*****"} */}
        {/* {!hideBalance && (
          <Tooltip
            title={
              !hideTooltip &&
              (tooltip || (
                <div>
                  <div>{final} ADA</div>
                  <div>
                    {config.currencySymbols[appCurrency]} {amountUSD}
                  </div>
                </div>
              ))
            }
          > */}
        <strong>
          {prefix || ""}
          {!shortened ? a : short}
        </strong>
        <span className={style.postfix}>
          {b && !hideDecimals && !shortened && <span>.{b}</span>} <span>ADA</span>
        </span>
        {/* </Tooltip>
        )} */}
      </div>
      {title && (
        <div className={style.title}>
          {title}{" "}
          {help && (
            <Tooltip title={help}>
              <i className="xi xi-info" />
            </Tooltip>
          )}
        </div>
      )}
    </div>
  )
}

export default InformerAda
