import React, { useEffect, useState } from "react"
import { Button } from "antd"
import { Link } from "gatsby"
import classnames from "classnames"
import { menuConfig } from "@/config/wiki"
import * as style from "./style.module.scss"

const Contents = () => {
  return (
    <div>
      {menuConfig.map((section, index) => (
        <div key={index} className="mb-5">
          <div className={style.title}>{section.title}</div>
          <div className={style.section}>
            {section.items.map((item, index) => (
              <Link key={index} to={item.link} className={style.link}>
                <span className="me-3">{item.title}</span>
                <i className="xi xi-arrow_forward ms-auto" />
              </Link>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

export default Contents
