import React, { useEffect, useState } from "react"
import { Modal, Button, Collapse, Form, Input, Tabs, Popconfirm } from "antd"
import classnames from "classnames"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import { SVGCardano } from "@/svg"
import * as style from "./style.module.scss"
import { AppActions } from "@/redux/app"
import { browserConnectors, browserConnectorData, ConfigTypes } from "@/config"
import { AccountActions, AccountSelectors } from "@/redux/account"
import * as utils from "@/utils"

const Vault = () => {
  const dispatch = useAppDispatch()
  const web3 = useAppSelector((state) => state.app.web3.instance)

  return (
    <div>
      <p className="text-muted">
        XRAY/Vault is a simple and secure solution for storing and using Cardano private keys, providing hardware
        key-level reliability directly on your mobile device or desktop. A secured{" "}
        <a href="https://vault.xray.app" target="_blank" className="xray-link-btn">
          Web App
          <i className="xi xi-open font-size-12 text-muted ms-1" />
        </a>{" "}
        and{" "}
        <a href="https://t.me/xray_vault_bot" target="_blank" className="xray-link-btn">
          Telegram Mini App
          <i className="xi xi-open font-size-12 text-muted ms-1" />
        </a>{" "}
        are available.
      </p>
    </div>
  )
}

export default Vault
