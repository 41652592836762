import React, { useState, useEffect } from "react"
import { Statistic, Tooltip } from "antd"
import * as style from "./style.module.scss"
import { useAppSelector, useAppDispatch } from "@/redux/provider"
import * as Utils from "@/utils"
import packageInfo from "../../../../package.json"

const Sidebar = () => {
  const tip = useAppSelector((state) => state.app.tip)
  const network = useAppSelector((state) => state.app.network)
  const [animate, setAnimate] = useState(false)

  useEffect(() => {
    setAnimate(true)
    const int = setInterval(() => {
      setAnimate(false)
    }, 700)
    return () => {
      clearInterval(int)
    }
  }, [tip?.blockNo])

  return (
    <div className="d-flex flex-column align-items-right justify-content-center">
      <div>
        <span className={`${style.dot} ${style.dotGreen} ${animate ? style.dotAnimate : ""}`} /> Block:{" "}
        {Utils.quantityWithCommas(tip?.blockNo || 0)} · Slot: {Utils.quantityWithCommas(tip?.absSlot || 0)}
      </div>
      <div>
        Epoch: {Utils.quantityWithCommas(tip?.epochNo || 0)} ·{" "}
        <span className="xray-countdown">
          <Statistic.Countdown
            value={new Date(Utils.epochEndTime(tip?.epochNo || 0, network.name) || "0").getTime()}
            format="D[d] HH[h] mm[m] ss[s]"
          />
        </span>
      </div>
      <div>
        Network: {Utils.capitalizeFirstLetter(network.name)} · Version: {packageInfo.version}
      </div>
    </div>
  )
}

export default Sidebar
