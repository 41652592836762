import React, { useEffect, useState } from "react"
import { Button } from "antd"
import { Link } from "gatsby"
import classnames from "classnames"
import { menuConfig } from "@/config/wiki"
import * as style from "./style.module.scss"

const Menu = () => {
  const [menuOpen, setMenuOpen] = useState(false)

  const openMenu = () => {
    setMenuOpen(true)
  }

  const closeMenu = () => {
    setMenuOpen(false)
    if (global?.window) {
      window.scrollTo(0, 0)
    }
  }

  return (
    <div>
      <div className={classnames("xray-mobile-menu", { "xray-mobile-menu-opened": menuOpen })}>
        <div className="xray-mobile-menu-item-outer">
          <Link
            to="/wiki"
            className="xray-mobile-menu-item"
            activeClassName="xray-mobile-menu-item-active"
            onClick={closeMenu}
          >
            Wiki Home
          </Link>
        </div>
        {menuConfig.map((section, index) => (
          <React.Fragment key={index}>
            <div className="xray-mobile-menu-item-outer">
              <div className="xray-mobile-menu-separator">{section.title}</div>
            </div>
            {section.items.map((item, index) => (
              <div key={index} className="xray-mobile-menu-item-outer">
                <Link
                  to={item.link}
                  className="xray-mobile-menu-item"
                  activeClassName="xray-mobile-menu-item-active"
                  onClick={closeMenu}
                >
                  {item.title}
                </Link>
              </div>
            ))}
          </React.Fragment>
        ))}
        <div className="xray-mobile-menu-close" onClick={closeMenu}>
          <i className="xi xi-close" />
        </div>
      </div>
      <div className={style.menu}>
        <div className="d-md-none d-block mb-5 mt--5">
          <div className="xray-line xray-line-dashed mb-4" />
          <Button shape="round" className="w-100p" size="large" onClick={openMenu}>
            <span>Wiki Menu</span>
            <i className="xi xi-menu ms-auto" />
          </Button>
        </div>
        <div className={classnames("d-md-block", "d-none")}>
          <div className={style.section}>
            <Link to="/wiki" activeClassName={style.active} onClick={closeMenu}>
              Wiki Home
            </Link>
          </div>
          {menuConfig.map((section, index) => (
            <div key={index} className={style.section}>
              <div className={style.title}>{section.title}</div>
              {section.items.map((item, index) => (
                <Link key={index} to={item.link} activeClassName={style.active} onClick={closeMenu}>
                  {item.title}
                </Link>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Menu
