import { MetadataTypes } from "."
import { getPersistSettings, setPersistSetting } from "@/services/localstorage"
import { LOCAL_STORAGE_PREFIX_GLOBAL } from "@/config"

const LOCAL_STORAGE_PREFIX = LOCAL_STORAGE_PREFIX_GLOBAL + "metadata."
const setLocalstorage = (key: string, value: any) => {
  setPersistSetting(LOCAL_STORAGE_PREFIX, key, value)
}

const initialState: MetadataTypes.ISettings = {}

export default (
  state: MetadataTypes.ISettings = { ...getPersistSettings(LOCAL_STORAGE_PREFIX, initialState) },
  action: MetadataTypes.Actions
): MetadataTypes.ISettings => {
  switch (action.type) {
    case MetadataTypes.Enum.CLEAR:
      return initialState

    default:
      return state
  }
}
