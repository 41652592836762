import React, { useEffect, useState } from "react"
import { Modal, Button, Collapse, Form, Input, Tabs, Checkbox, FormProps, Alert } from "antd"
import Unlock from "./Unlock"
import Create from "./Create"

type FormFieldsType = {
  key: string
}

const PrivateKey = () => {
  const [activeTab, setActiveTab] = useState("unlock")

  return (
    <div>
      <Tabs
        destroyInactiveTabPane
        className="mt--3"
        activeKey={activeTab}
        onChange={(key) => setActiveTab(key)}
        items={[
          {
            key: "unlock",
            label: (
              <strong>
                <i className="xi xi-unlock me-1"></i> Unlock
              </strong>
            ),
          },
          {
            key: "create_new",
            label: (
              <strong>
                <i className="xi xi-add_circled me-1"></i> Create New
              </strong>
            ),
          },
        ]}
      />
      {activeTab === "unlock" && <Unlock />}
      {activeTab === "create_new" && <Create />}
    </div>
  )
}

export default PrivateKey
