import React, { useEffect, useState } from "react"
import { Modal, Button, Collapse, Form, Input, Tabs, Alert } from "antd"
import classnames from "classnames"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import { SVGCardano } from "@/svg"
import * as style from "./style.module.scss"
import { AppActions } from "@/redux/app"
import { browserConnectors, browserConnectorData, ConfigTypes } from "@/config"
import { AccountActions, AccountSelectors } from "@/redux/account"
import * as utils from "@/utils"

const Popular = () => {
  const dispatch = useAppDispatch()
  const web3 = useAppSelector((state) => state.app.web3.instance)

  const [browserConnectorsAvailable, setBrowserConnectorsAvailable] = useState<ConfigTypes.BrowserConnectorData[]>([])

  useEffect(() => {
    if (web3) {
      ;(async () => {
        const available = (await web3.connector.list()).map((connector) => browserConnectorData(connector))
        setBrowserConnectorsAvailable(available)
      })()
    }
  }, [web3])

  const connectBrowserConnector = async (connectorId: string) => {
    dispatch(AccountActions.ADD_BROWSER_CONNECTOR_SAGA(connectorId))
  }

  return (
    <div>
      {!utils.isChromiumBased() && (
        <Alert
          className="mb-4"
          message="Browser not supported"
          description="Please use Chrome, Brave, or Edge browsers. Wallet extensions are only supported in Chromium-based browsers"
          type="warning"
          showIcon
        />
      )}
      {browserConnectors.map((connector, index) => {
        const isAvailable = browserConnectorsAvailable.find((c) => c.id === connector.id)
        return (
          <div className="xray-connect" key={index}>
            <div
              className={classnames("xray-connect-item", {
                "xray-connect-item-inactive": !isAvailable,
              })}
              onClick={() => (isAvailable ? connectBrowserConnector(connector.id) : void 0)}
            >
              <span className="xray-connect-item-img">
                <img
                  src={connector.imgUrl}
                  alt={connector.name}
                  className={classnames("xray-connect-item-icon", {
                    "xray-inverse-on-dark": connector.imgInversible,
                  })}
                />
              </span>
              <span className="xray-connect-item-title">
                <strong className="d-flex align-items-center justify-items-center">{connector.name}</strong>
              </span>
              {isAvailable && (
                <span className="xray-connect-item-status">
                  <i className="xi xi-arrow_forward me-1" />
                </span>
              )}
              {!isAvailable && (
                <span className="xray-connect-item-status xray-connect-item-status-active">
                  <a href={connector.installUrl} target="_blank" rel="noreferrer">
                    <span>Install</span>
                    <i className="xi xi-open ms-2" />
                  </a>
                </span>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default Popular
