import { combineReducers } from "redux"
import account from "./account/reducers"
import app from "./app/reducers"
import metadata from "./metadata/reducers"
import transaction from "./transaction/reducers"

const rootReducers = combineReducers({
  account,
  app,
  metadata,
  transaction,
})

export default rootReducers
