import React, { useEffect, useState } from "react"
import { CheckCircleFilled } from "@ant-design/icons"
import { Button } from "antd"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import { TransactionActions, TransactionSelectors, TransactionTypes } from "@/redux/transaction"
import { AccountActions, AccountSelectors, AccountTypes } from "@/redux/account"
import LinkExplorer from "@/components/common/LinkExplorer"

const Success = () => {
  const dispatch = useAppDispatch()
  const currentAccountPendingTx = useAppSelector(TransactionSelectors.currentAccountPendingTx)

  return (
    <div>
      <div className="mt-4 mb-4 pb-2">
        <div className="text-center text-success mb-3">
          <CheckCircleFilled style={{ fontSize: 72 }} />
        </div>
        <div className="text-center mt-2 font-size-24">
          <strong>Transaction Sent</strong>
        </div>
        <div className="text-center text-muted mt-2">
          <LinkExplorer
            title={currentAccountPendingTx?.hash || ""}
            link={currentAccountPendingTx?.hash || ""}
            type="transaction"
          />
        </div>
        {/* {(modalAdaTx.type === "delegate" || modalAdaTx.type === "deregister") && (
          <div className="text-center text-muted mt-2">
            Changes in the delegation will appear in the app within 10-20 seconds
          </div>
        )} */}
      </div>
      <div className="text-center mb-4">
        <Button size="large" shape="round" onClick={() => dispatch(TransactionActions.MODAL_TRANSACTION_SET("", ""))}>
          <i className="xi xi-close_circled" />
          <span>Close</span>
        </Button>
      </div>
    </div>
  )
}

export default Success
