import * as utils from "@/utils"
import * as ConfigTypes from "./types"
export * as ConfigTypes from "./types"
export { miniAppsConfig } from "./apps"

export const LOCAL_STORAGE_PREFIX_GLOBAL = "xray.app."

export const TTL = 900

export const ADA_HANDLE_POLICY = "f0ff48bbb7bbe9d59a40f1ce90e9e9d0ff5002ec48f232b49ca0fb9a"

export const SLOT_CONFIG_NETWORK: Record<ConfigTypes.T.NetworkName, ConfigTypes.T.SlotConfig> = {
  mainnet: { zeroTime: 1596059091000, zeroSlot: 4492800, slotDuration: 1000 },
  preview: { zeroTime: 1666656000000, zeroSlot: 0, slotDuration: 1000 },
  preprod: { zeroTime: 1654041600000 + 1728000000, zeroSlot: 86400, slotDuration: 1000 },
  custom: { zeroTime: 0, zeroSlot: 0, slotDuration: 0 },
}

export const SLOT_STARTING_EPOCH: Record<ConfigTypes.T.NetworkName, number> = {
  mainnet: 208,
  preprod: 4,
  preview: 0,
  custom: 0,
}

export const SLOT_EPOCH_DURATION: Record<ConfigTypes.T.NetworkName, number> = {
  mainnet: 432000,
  preprod: 432000,
  preview: 432000 / 5,
  custom: 0,
}

export const browserConnectors: ConfigTypes.BrowserConnectorData[] = [
  // {
  //   id: "xray",
  //   name: "XRAY/Wallet",
  //   imgUrl: "/resources/wallets/xray.svg",
  //   imgInversible: false,
  //   installUrl: "https://xray.app", // TODO: Update this URL
  // },
  {
    id: "eternl",
    name: "Eternl",
    imgUrl: "/resources/wallets/eternl.png",
    imgInversible: false,
    installUrl: "https://cardwallet.io",
  },
  {
    id: "metamask",
    name: "MetaMask",
    imgUrl: "/resources/wallets/metamask.svg",
    imgInversible: false,
    installUrl: "https://metamask.io",
  },
  {
    id: "nami",
    name: "Nami",
    imgUrl: "/resources/wallets/nami.svg",
    imgInversible: false,
    installUrl: "https://namiwallet.io",
  },
  {
    id: "lace",
    name: "Lace",
    imgUrl: "/resources/wallets/lace.svg",
    imgInversible: false,
    installUrl: "https://lace.io",
  },
  {
    id: "nufi",
    name: "NuFi",
    imgUrl: "/resources/wallets/nufi.svg",
    imgInversible: false,
    installUrl: "https://nu.fi",
  },
  {
    id: "vespr",
    name: "Vespr",
    imgUrl: "/resources/wallets/vespr.png",
    imgInversible: false,
    installUrl: "https://vespr.xyz",
  },
  {
    id: "yoroi",
    name: "Yoroi",
    imgUrl: "/resources/wallets/yoroi.svg",
    imgInversible: false,
    installUrl: "https://yoroi-wallet.com",
  },
  {
    id: "exodus",
    name: "Exodus",
    imgUrl: "/resources/wallets/exodus.svg",
    imgInversible: false,
    installUrl: "https://www.exodus.com",
  },
  {
    id: "typhoon",
    name: "Typhoon",
    imgUrl: "/resources/wallets/typhoon.svg",
    imgInversible: false,
    installUrl: "https://typhonwallet.io",
  },
  {
    id: "gero",
    name: "Gero",
    imgUrl: "/resources/wallets/gero.svg",
    imgInversible: false,
    installUrl: "https://gerowallet.io",
  },
  {
    id: "begin",
    name: "Begin",
    imgUrl: "/resources/wallets/begin.svg",
    imgInversible: false,
    installUrl: "https://begin.is",
  },
]

export const browserConnectorData = (id: string): ConfigTypes.BrowserConnectorData => {
  return (
    browserConnectors.find((connector) => connector.id === id) ?? {
      id,
      name: utils.capitalizeFirstLetter(id),
      imgUrl: "/resources/wallets/wallet.png",
      imgInversible: true,
      installUrl: "",
    }
  )
}
