export type FiatPriceList = {
  usd: number
  eur: number
  gbp: number
  jpy: number
  cny: number
}

export type FiatPriceResponse = {
  updatedAt: number
  price: {
    bitcoin: FiatPriceList | null
    cardano: FiatPriceList | null
    ethereum: FiatPriceList | null
    "ray-network": FiatPriceList | null
  }
}

export const getFiatPrice = async (): Promise<FiatPriceResponse> => {
  return (await fetch("https://graph.xray.app/output/fiat_price")).json()
}
